body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* basir mama code */

html {
  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}
header {
  grid-area: header;
  background-color: #203040;
}
main {
  grid-area: main;
  padding: 1rem;
}
footer {
  grid-area: footer;
  background-color: #203040;
  color: #ffffff;
}
/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}

a {
  text-decoration: none;
}
a:hover {
  color: #ff8000;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  cursor: pointer;
}
button:hover {
  border: 0.1rem #404040 solid;
}
button.primary {
  background-color: #f0c040;
}
button.block {
  width: 100%;
}
/* Header */
header a {
  color: #ffffff;
  padding: 1rem;
}
a.brand {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.col-1 {
  flex: 1 1 25rem;
}
.col-2 {
  flex: 2 1 50rem;
}

/* Image */
img {
  border-radius: 0.5rem;
}
img.medium {
  max-width: 29rem;
  width: 100%;
}
img.large {
  width: 100%;
}

/* Card */
.card {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
}
.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 2rem;
}
/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #404040;
}
/* Alert */
.success {
  color: #20a020;
}
.error {
  color: #a02020;
}

